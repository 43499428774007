<template>
  <div>
    <el-card v-if="!id" class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">题库详情</span>
        <div class="search">
          <div class="totalNum">题目总数：{{ pager.total }}</div>
          <div class="searchInput">
            <el-input
              v-model="title"
              placeholder="请输入标题名称"
              maxlength="64"
              class="input-width"
              clearable
            ></el-input>
            <div class="searchBtn" @click="search"><i class="el-icon-search"></i></div>
          </div>
        </div>
      </div>
      <div v-for="(item, index) in list" :key="item.questionId" class="subject">
        <div class="subjectWrap">
          <div class="subjectTitle">
            <div class="index">{{ index + 1 + count }}.</div>
            <div v-if="item.questionType == 1" class="subjectType">单选题</div>
            <div v-if="item.questionType == 2" class="subjectType">多选题</div>
            <!-- <div v-if="item.questionType == 4" class="subjectType">判断题</div>
            <div v-if="item.questionType == 5" class="subjectType">填空题</div> -->
            <div class="subjectName">
              {{ item.questionName }}
            </div>
          </div>
          <div class="delIcon" @click="delTest(item)"><i class="el-icon-delete"></i></div>
        </div>
        <div v-for="infoItem in item.optionList" :key="infoItem.questionOptionId">
          <div class="subjectOptions">
            <span class="optionsTag">{{ infoItem.optionTag }}.</span>
            <span class="optionsName">{{ infoItem.optionName }}</span>
          </div>
        </div>
        <div class="analysis">
          <span class="answer">正确答案</span>
          <span v-for="(citem, cindex) in item.rightOptionList" :key="cindex">{{
            citem.optionTag
          }}</span>
        </div>
      </div>
      <el-pagination
        :current-page.sync="pager.current"
        background
        :page-sizes="[10, 20, 50]"
        :page-size="pager.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pager.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>

    <div v-else class="testDetail">
      <el-row :gutter="20">
        <el-col :span="14">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="title">{{ detail.examTitle }}考试详情</span>
            </div>
            <div
              v-for="(item, index) in list"
              :id="item.questionId"
              :key="item.questionId"
              class="subject"
            >
              <div class="subjectWrap">
                <div class="subjectTitle">
                  <div class="index">{{ index + 1 + (pager.current - 1) * pager.size }}.</div>
                  <div v-if="item.questionType == 1" class="subjectType">单选题</div>
                  <div v-if="item.questionType == 2" class="subjectType">多选题</div>
                  <!-- <div v-if="item.questionType == 4" class="subjectType">判断题</div>
                  <div v-if="item.questionType == 5" class="subjectType">填空题</div> -->
                  <div class="subjectName">
                    {{ item.questionName }} <span>({{ item.score }}分)</span>
                  </div>
                </div>
              </div>
              <div v-for="infoItem in item.optionList" :key="infoItem.questionOptionId">
                <div class="subjectOptions">
                  <span class="optionsTag">{{ infoItem.optionTag }}.</span>
                  <span class="optionsName">{{ infoItem.optionName }}</span>
                </div>
              </div>
              <div class="analysis">
                <span class="answer">考生答案</span>
                <span v-for="(citem, cindex) in item.answerOptionList" :key="cindex">{{
                  citem.optionTag
                }}</span>
              </div>
              <div class="analysis">
                <span class="answer">正确答案</span>
                <span v-for="(citem, cindex) in item.rightOptiionList" :key="cindex">{{
                  citem.optionTag
                }}</span>
              </div>
              <div class="analysis">
                <span class="answer">正确解析</span>
                <span>{{ item.analysis }}</span>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="10" class="elROW10">
          <div class="testCol">
            <el-card class="testResult">
              <div class="title">考试结果</div>
              <div class="testInfo">
                <div class="testTitle">{{ detail.examTitle }}</div>
              </div>
              <div class="score">
                <div class="fullMark">
                  <span class="testTitle">总得分:</span>
                  <span class="finalScore">{{ detail.scoreTotal }}分</span>
                </div>
                |
                <div class="people">
                  <div>出卷人</div>
                  <div>{{ detail.drawer }}</div>
                </div>
              </div>
              <div class="detail">
                <div class="num">{{ detail.finalScore }}</div>
                <div class="name">{{ detail.studentName }}</div>
                <div>交卷时间: {{ detail.submitTime }}</div>
              </div>
            </el-card>
            <el-card class="choice">
              <div class="answerWarp">
                <div class="choiceAnswer">
                  <div class="title">单选题</div>
                  <div class="answerBox">
                    <div
                      v-for="(kitem, kindex) in list.filter(item => item.questionType == 1)"
                      :key="kitem.questionId"
                      class="answerItem"
                      :class="{
                        correct: kitem.isRight == 1,
                        error: kitem.isRight == 2,
                        unanswered: kitem.isRight == 0,
                      }"
                      @click="goAnswerItem(kitem.questionId)"
                    >
                      {{ kindex + 1 }}
                    </div>
                  </div>
                </div>
                <div v-if="list.filter(item => item.questionType == 1).length" class="choiceAnswer">
                  <div class="title">多选题</div>
                  <div class="answerBox">
                    <div
                      v-for="(kitem, kindex) in list.filter(item => item.questionType == 2)"
                      :key="kitem.questionId"
                      class="answerItem"
                      :class="{
                        correct: kitem.isRight == 1,
                        error: kitem.isRight == 2,
                        unanswered: kitem.isRight == 0,
                      }"
                      @click="goAnswerItem(kitem.questionId)"
                    >
                      {{ kindex + 1 }}
                    </div>
                  </div>
                </div>
                <div v-if="list.filter(item => item.questionType == 3).length" class="choiceAnswer">
                  <div class="title">问答题</div>
                  <div class="answerBox">
                    <div
                      v-for="(kitem, kindex) in list.filter(item => item.questionType == 3)"
                      :key="kitem.questionId"
                      class="answerItem"
                      :class="{
                        correct: kitem.isRight == 1,
                        error: kitem.isRight == 2,
                        unanswered: kitem.isRight == 0,
                      }"
                      @click="goAnswerItem(kitem.questionId)"
                    >
                      {{ kindex + 1 }}
                    </div>
                  </div>
                </div>
                <!-- <div
                  v-for="(kitem, kindex) in list"
                  :key="kitem.questionId"
                  class="answerItem"
                  :class="{
                    correct: kitem.isRight == 1,
                    error: kitem.isRight == 2,
                    unanswered: kitem.isRight == 0,
                  }"
                  @click="goAnswerItem(kitem.questionId)"
                >
                  {{ kindex + 1 }}
                </div> -->
              </div>
              <div class="status">
                <div class="statusInfo">
                  <div class="correct bgc"></div>
                  <div class="text">正确</div>
                </div>
                <div class="statusInfo">
                  <div class="error bgc"></div>
                  <div class="text">错误</div>
                </div>
                <div class="statusInfo">
                  <div class="unanswered bgc"></div>
                  <div class="text">未作答</div>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { questionDetail, testResultDetail, questionDel } from '@/api/examination'
import to from 'await-to'
export default {
  data() {
    return {
      title: '',
      list: [],
      questionStoreId: this.$route.query.questionStoreId,
      id: this.$route.query.id,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      detail: {},
      count: '',
    }
  },
  created() {
    if (this.questionStoreId && !this.id) {
      this.questionDetailData()
    } else {
      this.testResultDetailData()
    }
  },
  methods: {
    async testResultDetailData() {
      const [res, err] = await to(testResultDetail({ id: this.id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.detail = res.data
      this.list = res.data.questionList
      this.list.filter(item => item.questionType)
      this.pager.total = res.data.total
    },
    async questionDetailData() {
      this.count = (this.pager.current - 1) * this.pager.size
      const { size, current } = this.pager
      const [res, err] = await to(
        questionDetail({
          size,
          current,
          questionStoreId: this.questionStoreId,
          questionName: this.title,
        }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data.list
      this.pager.total = res.data.total
    },
    goAnswerItem(hrefId) {
      this.$nextTick(() => {
        document
          .getElementById(hrefId)
          .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
      })
    },
    async delTest(item) {
      const [, err] = await to(
        questionDel({ questionStoreId: Number(this.questionStoreId), questionId: item.questionId }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '删除成功' })
      this.questionDetailData()
    },
    search() {
      this.pager.current = 1
      this.questionDetailData()
    },
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.questionDetailData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.questionDetailData()
    },
  },
}
</script>

<style scoped lang="scss">
.search {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  .totalNum {
    color: #909399;
    font-size: 14px;
  }
  .searchInput {
    line-height: 38px;
    display: flex;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    .input-width {
      width: 296px;
      height: 38px;
      ::v-deep input {
        border: none;
      }
    }
    .searchBtn {
      cursor: pointer;
      width: 57px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      background: #ff7b33;
      i {
        font-size: 16px;
        color: #fff;
      }
    }
  }
}

.subject {
  overflow: hidden;
  .subjectWrap {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    .subjectTitle {
      float: left;
      display: flex;
      align-items: center;
      .index {
        font-size: 14px;
      }
      .subjectType {
        padding: 3px 10px;
        background: #8c82f5;
        border-radius: 12px;
        color: #fff;
        margin: 0 4px;
      }
      .subjectName {
        font-size: 14px;
        color: #333;
      }
    }
    .delIcon {
      font-size: 25px;
      margin-left: 80px;
      cursor: pointer;
    }
  }
  .subjectOptions {
    line-height: 30px;
    font-size: 14px;
    color: #333;
    .optionsTag {
      margin-right: 10px;
    }
  }
  .analysis {
    font-size: 14px;
    margin: 30px 0;
    .answer {
      color: #03c53d;
      margin-right: 15px;
    }
  }
}

.testCol {
  .testResult {
    .title {
      color: #333333;
      font-size: 20px;
      text-align: center;
      margin-bottom: 24px;
    }
    .testInfo {
      font-size: 14px;
      .testTitle {
        color: #333333;
      }
    }
    .score {
      display: flex;
      align-items: center;
      margin-top: 11px;
      font-size: 14px;
      color: #909399;
      .fullMark {
        display: flex;
        margin-right: 15px;
      }
      .people {
        display: flex;
        margin-left: 15px;
      }
    }
    .detail {
      margin-top: 30px;
      margin-bottom: 28px;
      text-align: center;
      .num {
        color: #ff7b33;
        font-size: 36px;
      }
      .name {
        color: #333333;
        font-size: 14px;
        margin: 10px 0;
      }
    }
  }
  .choice {
    margin-top: 20px !important;
    ::v-deep .el-card__body {
      padding: 0;
    }
    .answerWarp {
      border-bottom: 1px solid #e2e2e2;
      padding-bottom: 50px;
      font-size: 14px;
      padding: 24px 20px 100px 20px;
      .choiceAnswer {
        .title {
          color: #333333;
          font-size: 16px;
          margin-bottom: 10px;
        }
        .answerBox {
          display: flex;
          flex-wrap: wrap;
          .answerItem {
            cursor: pointer;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 50%;
            text-align: center;
            color: #fff;
            margin-right: 32px;
            margin-bottom: 20px;
            transition: 0.5s ease;
            &:hover {
              transform: scale(1.5);
            }
          }
        }
      }
    }
    .status {
      display: flex;
      float: right;
      .statusInfo {
        display: flex;
        align-items: center;
        margin: 19px;
      }
      .bgc {
        width: 14px;
        height: 14px;
        border-radius: 50%;
      }
      .text {
        font-size: 16px;
        margin-left: 5px;
        color: #333333;
      }
    }
  }
}

.correct {
  background-color: #67c23a;
}
.error {
  background-color: #f56c6c;
}
.unanswered {
  background-color: gray;
}
</style>
